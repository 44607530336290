import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  GoogleIconWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  TermsAndPolicyText,
  CredentialSection,
  ErrorMessage,
  ImageSection,
  FormContainer,
  CarouselContainer,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import {
  EyeIcon,
  EyeIconButton,
  StyledCheckbox,
} from "../../../components/commonComponents/formFields/style";
import { signUpValidationSchema } from "../../../utils/formValidationSchema";
import { SignUpFormValuesProp } from "../../../utils/propTypes";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { GoogleIcon } from "../../../assets/Icons";
import { SignUpService } from "../../../services/AuthServices";
import { useNavigate } from "react-router-dom";
import Carousel from "../../../components/commonComponents/carousel";
import { CarouselContext } from "../../../context/CarouselContext";
import { AuthContext } from "../../../context/AuthContext";

const SignupForm = () => {
  const { setAuthStatus, isAuthenticated } = useContext(AuthContext);
  const { carouselImages } = useContext(CarouselContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<SignUpFormValuesProp>({
    resolver: yupResolver(signUpValidationSchema),
  });
  const onSubmit = async () => {
    const values = getValues();
    const response: any = await SignUpService(values);
    if (response?.status) {
      const data = { ...response.data.data, isLoggedIn: true };
      setAuthStatus(true);
      localStorage.setItem("User", JSON.stringify(data));
      reset();
      navigate("/");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(-1);
    }
  }, []);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginLayoutContainer container>
      <FormContainer>
        <CredentialSection xs={12} md={6}>
          <LogoWrapper src={Logo} alt="app-logo" />
          <PageTitle>Sign Up</PageTitle>
          <AccountText>
            {"Already have an account ? "}
            <AccountLoginText to="/login">Login</AccountLoginText>
          </AccountText>
          <FormWrapper>
            <FormFieldWrapper>
              <FormControl
                fullWidth
                sx={{
                  "& > label": {
                    top: 23,
                    left: 0,
                    color: "white",
                    '&[data-shrink="false"]': {
                      top: 5,
                    },
                  },
                  "& > div > input": {
                    padding: "30.5px 14px 11.5px !important",
                    color: "white",
                    border: "3px solid white",
                    borderRadius: "2px",
                    zIndex: 2,
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
              >
                <InputLabel
                  style={{ color: "white" }}
                  htmlFor="outlined-adornment-fullname-login"
                >
                  Full Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-fullname-login"
                  type="text"
                  {...register("fullName", { required: true })}
                />
              </FormControl>
              {errors.email?.message && (
                <ErrorMessage>{errors.fullName?.message}</ErrorMessage>
              )}

              <FormControl
                fullWidth
                sx={{
                  "& > label": {
                    top: 23,
                    left: 0,
                    color: "white",
                    '&[data-shrink="false"]': {
                      top: 5,
                    },
                  },
                  "& > div > input": {
                    padding: "30.5px 14px 11.5px !important",
                    color: "white",
                    border: "3px solid white",
                    borderRadius: "2px",
                    zIndex: 2,
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
              >
                <InputLabel
                  style={{ color: "white" }}
                  htmlFor="outlined-adornment-email-login"
                >
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-login"
                  type="email"
                  {...register("email", { required: true })}
                />
              </FormControl>
              {errors.email?.message && (
                <ErrorMessage>{errors.email?.message}</ErrorMessage>
              )}

              <FormControl
                fullWidth
                sx={{
                  "& > label": {
                    top: 23,
                    left: 0,
                    color: "white",
                    '&[data-shrink="false"]': {
                      top: 5,
                    },
                  },
                  "& > div > input": {
                    padding: "30.5px 14px 11.5px !important",
                    color: "white",

                    zIndex: 2,
                  },
                  "& > div": {
                    border: "3px solid white",
                    borderRadius: "2px",
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
              >
                <InputLabel
                  style={{
                    width: "100%",
                    color: "white",
                  }}
                  htmlFor="outlined-adornment-password-login"
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-login"
                  type={showPassword ? "text" : "password"}
                  {...register("password", { required: true })}
                  label="Password"
                  endAdornment={
                    <EyeIconButton
                      onClick={(e) => {
                        e.preventDefault();
                        handlePassword();
                      }}
                    >
                      <EyeIcon
                        src="/assets/images/eye-icon.svg"
                        alt="show-hide-password-icon"
                      />
                    </EyeIconButton>
                  }
                />
              </FormControl>
              {errors.password?.message && (
                <ErrorMessage>{errors.password?.message}</ErrorMessage>
              )}
            </FormFieldWrapper>
            <TermsAndPolicyText>
              <StyledCheckbox size="small" />
              Agree to The Terms & Conditions and Privacy Policy
            </TermsAndPolicyText>

            <BottomButtonWrapper>
              <StyledLoginButton
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Sign Up
              </StyledLoginButton>
              <StyledLoginButton variant="contained" withGoogle>
                <GoogleIconWrapper>
                  <GoogleIcon />
                </GoogleIconWrapper>
                Sign Up with Google
              </StyledLoginButton>
            </BottomButtonWrapper>
          </FormWrapper>
        </CredentialSection>
      </FormContainer>
      <CarouselContainer>
        <ImageSection>
          {carouselImages.carousel_one?.length > 0 && (
            <>
              <Carousel imageList={carouselImages.carousel_one} />
              <Carousel imageList={carouselImages.carousel_two} rtl={true} />
              <Carousel imageList={carouselImages.carousel_three} />
            </>
          )}
        </ImageSection>
      </CarouselContainer>
    </LoginLayoutContainer>
  );
};

export default SignupForm;
