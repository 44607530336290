import { MenuPopoverProps } from "../../types/Popover";
import { Checkbox, Menu, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import { StyledFormGroup } from "../popover/style";
import { CheckedIcon, CreateNewIcon, UncheckedIcon } from "../../assets/Icons";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  boardIdsSelector,
  boardItemsSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const SaveAdPopover = ({ anchorEl, handlePopoverClose }: MenuPopoverProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const boardItems = useSelector(boardItemsSelector);
  const selectedBoardIds = useSelector(boardIdsSelector);

  const onChangeHandler = (boardId: number) => {
    dispatch(MetaDataReducerAction.setBoardId(boardId));
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiMenu-paper": {
            borderRadius: "15px",
            padding: "22px 20px 20px 20px",
            width: "23%",
          },
          ".MuiList-root": {
            padding: "0",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          },
        }}
      >
        <Typography
          fontSize="1.125rem"
          fontWeight="700"
          color={theme.colors.black2}
        >
          Save to Board
        </Typography>
        <StyledFormGroup>
          <FilterSearchbox placeholder="Search" />
          {boardItems?.map((board: any, index: any) => (
            <Stack
              key={index}
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                fontSize="0.938rem"
                fontWeight="500"
                color={theme.colors.black2}
              >
                {board.name}
              </Typography>
              <Checkbox
                key={index}
                disableRipple
                onChange={() => onChangeHandler(board._id)}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
                sx={{ padding: 0 }}
                checked={selectedBoardIds.includes(board._id)}
              />
            </Stack>
          ))}
        </StyledFormGroup>
        <Stack
          direction="row"
          sx={{ alignItems: "center", gap: "10px" }}
          onClick={() =>
            dispatch(MetaDataReducerAction.setOpenBoardModal(true))
          }
        >
          <CreateNewIcon fill={theme.colors.black2} />
          <Typography
            fontSize="1rem"
            fontWeight="600"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Create New Board
          </Typography>
        </Stack>
      </Menu>

    </>
  );
};

export default memo(SaveAdPopover);
