import { MenuPopoverProps } from "../../types/Popover";
import { Avatar, Divider, Menu, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import AvatarIcon from "../../assets/images/avatar-icon.png";
import {
  HelpBlackIcon,
  LogoutIcon,
  SaveIcon,
  SettingsIcon,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const ItemRow = ({
  children,
  eventName,
}: {
  children: React.ReactNode;
  eventName?: string;
}) => {
  const navigate = useNavigate();
  const { setAuthStatus } = useContext(AuthContext);
  const handleClick = () => {
    if(eventName === 'logout'){
      setAuthStatus(false);
      localStorage.clear()
      navigate('/login')
    }
  }
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", gap: "10px" }}
      onClick={handleClick}
    >
      {children}
    </Stack>
  );
};

const StyledText = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="0.938rem"
      fontWeight="500"
      color={theme.colors.black2}
      style={{
        cursor: 'pointer'
      }}
    >
      {children}
    </Typography>
  );
};

const ProfilePopover = ({ anchorEl, handlePopoverClose }: MenuPopoverProps) => {
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "15px",
          padding: "15px",
          width: "17%",
        },
        ".MuiList-root": {
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        },
      }}
    >
      <ItemRow>
        <Avatar src={AvatarIcon} />
        <Stack>
          <Typography
            fontSize="1rem"
            fontWeight="600"
            color={theme.colors.black2}
          >
            Esther Howard
          </Typography>
          <Typography
            fontSize="0.75rem"
            fontWeight="400"
            color={theme.colors.gray1}
          >
            estherhoward@gmail.com
          </Typography>
        </Stack>
      </ItemRow>
      <Divider sx={{ marginInline: "-15px" }} />
      <ItemRow>
        <SettingsIcon />
        <StyledText>Settings</StyledText>
      </ItemRow>
      <ItemRow>
        <SaveIcon />
        <StyledText>Saved Ads</StyledText>
      </ItemRow>
      <ItemRow>
        <HelpBlackIcon />
        <StyledText>Help</StyledText>
      </ItemRow>
      <Divider sx={{ marginInline: "-15px" }} />
      <ItemRow eventName="logout">
        <LogoutIcon />
        <StyledText>Logout</StyledText>
      </ItemRow>
    </Menu>
  );
};

export default ProfilePopover;
