import SelectDropdown from "../../selectDropdown";
import { BoardIcon } from "../../../assets/Icons";
import { Box, Chip, SelectChangeEvent, Stack } from "@mui/material";
import { StyledChip, StyledMenuItem } from "../../../globalStyle";
import FilterSearchbox from "../filterSearchbox";
import { staticBoards } from "../../../utils/constants";
import { SelectBoardDropdownProps } from "../../../utils/propTypes";

const SelectBoardDropdown = ({
  setBoards,
  boards,
  showSelectedItemCount,
}: SelectBoardDropdownProps) => {
  return (
    <SelectDropdown
      startAdornmentIcon={<BoardIcon />}
      multiple={true}
      renderValue={(selected: any) => {
        return (
          <Stack
            direction="row"
            justifyContent={
              showSelectedItemCount === 1 ? "space-between" : "start"
            }
          >
            {selected.slice(0, showSelectedItemCount).map((item: string) => (
              <Chip key={item} label={item} />
            ))}
            {selected.length > showSelectedItemCount && (
              <StyledChip
                label={`+${selected.length - showSelectedItemCount}`}
              />
            )}
          </Stack>
        );
      }}
      handleChange={(event: SelectChangeEvent) => {
        const selectedValue = event.target.value;
        setBoards(
          typeof selectedValue === "string"
            ? selectedValue.split(",")
            : selectedValue
        );
      }}
      value={boards}
    >
      <Box sx={{ margin: "15px" }}>
        <FilterSearchbox placeholder="Search" />
      </Box>
      {staticBoards.map((board, index) => (
        <StyledMenuItem key={index} value={board.label}>
          {board.label}
        </StyledMenuItem>
      ))}
    </SelectDropdown>
  );
};

export default SelectBoardDropdown;
