import { Button } from "@mui/material";
import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  padding: 3% 6% 3% 3%;
  justify-content: space-between;
  align-items: center;
`;

export const CardContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.border1}`};
  border-radius: 10px;
  background-color: white;

  &:hover .save-button,
  &:hover .overlay {
    display: block;
  }
`;

export const SaveAdButton = styled(Button)`
  &.MuiButton-root {
    background-color: white !important;
    color: ${({ theme }) => theme.colors.black3} !important;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 22px;
    padding: 0.625rem 1.375rem;
    z-index: 1;
    display: none;
  }
`;

export const CardImageWrapper = styled.div`
  position: relative;
`;

export const CardImage = styled.img`
  width: 100%;
  margin-bottom: -4px;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  border-radius: 0 0 10px 10px;
  display: none;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
