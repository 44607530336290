import { Drawer, Grid, Stack } from "@mui/material";
import { AdDrawerProps } from "../../../utils/propTypes";
import { AdDrawerStyledButton, StyledGridItem } from "./style";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../../globalStyle";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import routeConstant from "../../../utils/routeConstant";
import AdDrawerHeader from "./header";
import AdCardSection from "./adCardSection";
import CommentBox from "./commentBox";
import OrganizationBox from "./organizationBox";

const AdDrawer = ({ open, handleClose, item }: AdDrawerProps) => {
  const theme = useTheme();
  const location = useLocation();
  const isSwipeFilePage = useMemo(
    () => location.pathname.includes(routeConstant.swipeFiles),
    [location.pathname]
  );

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "15px 15px 0 0",
          height: "87%",
          overflow: "hidden",
        },
      }}
    >
      <AdDrawerHeader />
      <StyledDivider />
      <Grid container>
        <AdCardSection item={item} />
        <StyledGridItem
          item
          sm={5}
          sx={{
            padding: "1.25rem 1.875rem 1.875rem 1.25rem",
            gap: "1.25rem",
            flexDirection: "column",
          }}
        >
          <OrganizationBox isSwipeFilePage={isSwipeFilePage} />
          <CommentBox />
          <Stack direction="row" alignItems="center" gap="10px">
            <AdDrawerStyledButton fullWidth>Download</AdDrawerStyledButton>
            <AdDrawerStyledButton
              fullWidth
              borderNone={true}
              sx={{
                backgroundColor: `${theme.colors.background4} !important`,
              }}
            >
              Thumbnail
            </AdDrawerStyledButton>
          </Stack>
          {isSwipeFilePage && (
            <AdDrawerStyledButton
              fullWidth
              borderNone={true}
              textColor="white"
              sx={{
                backgroundColor: `${theme.colors.red2} !important`,
              }}
            >
              Delete
            </AdDrawerStyledButton>
          )}
        </StyledGridItem>
      </Grid>
    </Drawer>
  );
};

export default memo(AdDrawer);
