import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const HeaderButton1 = styled(Button)`
  border-radius: 2rem !important;
  color: #00aeef !important;
`;

export const HeaderButton2 = styled(Button)`
  border-radius: 2rem !important;
  color: white !important;
  background: linear-gradient(135deg, #1c75bc, #00aeef) !important;
`;

export const AdsContainer = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const HeaderSection = styled.div`
  background-color: white;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  border-radius: 20px 0 0 20px;
`;

export const HeaderMenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid #eaecee;
  height: 10%;
`;

export const HeaderLeftMenuSection = styled.div`
  display: flex;
`;

export const HamburgerMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem 0 0.5rem;
  border-right: 1px solid #eaecee;
  cursor: pointer;
`;

export const HeaderTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

export const HeaderRightMenuSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 20px !important;
  font-weight: 700 !important;
  color: ${({ theme }) => `1px solid ${theme.colors.black2}`};
`;

export const HeaderSubTitle = styled(Typography)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: ${({ theme }) => `1px solid ${theme.colors.gray1}`};
`;

export const ButtonText = styled(Typography)`
  font-size: 14px !important;
  margin: 5px !important;
`;

export const SearchFilterSection = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 2rem !important;
  border-bottom: 0.5px solid #eaecee !important;
  height: 10% !important;
`;

export const SearchWrapper = styled(FormControl)`
  margin: 10px;
  width: 30.8rem;
  height: 2.5rem;
`;

export const SearchIconWrapper = styled(InputAdornment)`
  position: absolute;
  right: 0;
  background-color: black;
  color: white !important;
  padding: 4px;
  height: 100% !important;
  width: 10%;
  border-radius: 0 20px 20px 0;
  display: flex;
  border: 0.5px solid #eaecee !important;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled(OutlinedInput)`
  border-radius: 20px !important;
  height: 100%;
`;

export const OptionSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const ViewTypeSelection = styled.div`
  border: 0.5px solid #eaecee !important;
  height: 2.5rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ViewButton1 = styled.button`
  padding: 3px 8px;
  border-radius: 20px 0 0 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: white;
  cursor: pointer;
`;
export const ViewButton2 = styled.button`
  padding: 3px 8px;
  height: 100%;
  background-color: black;
  border-radius: 0 20px 20px 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const OptionButtonText = styled(Typography)`
  font-size: 14px !important;
  text-transform: math-auto;
`;

export const AppliedFilterText = styled(Typography)`
  font-size: 14px !important;
  text-transform: math-auto;
  color: ${({ color }) => `${color}`} !important;
`;
export const OptionButton = styled(Button)`
  text-transform: math-auto;
  display: flex;
  gap: 10px;
  && {
    border-radius: 20px;
    border: 0.5px solid #eaecee;
    height: 2.5rem;
    color: ${({ theme }) => `${theme.colors.black2}`};
    padding: 0 26px 0 15px;
  }
`;
