import Slider from "react-slick";
import { CarouselProps } from "../../../utils/propTypes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ imageList, rtl }: CarouselProps) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    vertical: true,
    cssEase: "linear",
    rtl,
    arrows: false,
    pauseOnHover: false,
    style: { width: "203px", marginRight: 32 },
  };
  return (
    <div>
      <Slider {...settings}>
        {imageList?.map((image, index) => (
          <div key={index}>
            <img src={image?.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
