import { call, put } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { MetaDataReducerAction } from "./slice";
import { AxiosResponse } from "axios";
import {
  CreateFolderService,
  FetchFolderListService,
} from "../../services/FolderService";
import { BoardService, CreateBoardService } from "../../services/BoardServices";

const saga = createSliceSaga({
  name: "metaData-state",
  caseSagas: {
    *createFolder(action: any) {
      try {
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          CreateFolderService,
          action.payload
        );

        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setIsLoading(false));
          yield put(MetaDataReducerAction.setOpenCreateFolder(false));
        }
      } catch (error) {
        throw new Error();
      }
    },
    *fetchFolderList() {
      try {
        const response: AxiosResponse = yield call(FetchFolderListService);

        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setFolderItems(response?.data?.data));
        }
      } catch (error) {
        throw new Error();
      }
    },
    *fetchBoardList() {
      try {
        const response: AxiosResponse = yield call(BoardService);

        yield put(MetaDataReducerAction.setBoardItems(response?.data?.data));
      } catch (error) {
        throw new Error();
      }
    },
    *createBoard(action: any) {
      try {
        yield put(MetaDataReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          CreateBoardService,
          action.payload
        );

        if (response?.status === 200) {
          yield put(MetaDataReducerAction.setIsLoading(false));
          const responseObj = {
            _id: response?.data?.data?._id,
            name: response?.data?.data?.name,
          };
          yield put(MetaDataReducerAction.setBoardItem(responseObj));
          yield put(
            MetaDataReducerAction.setBoardId(response?.data?.data?._id)
          );
          yield put(MetaDataReducerAction.setOpenBoardModal(false));
        }
      } catch (error) {
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: MetaDataSagaActions,
  saga: MetaDataSaga,
  name: MetaDataSagaName,
} = saga;
