import { PanelItem, PanelLogo } from "../../pageComponents/auth/style";
import {
  DashboardIcon,
  DiscoveryIcon,
  HelpIcon,
  LogoSmall,
  SwipeFileIcon,
  TeamIcon,
} from "../../assets/Icons";
import { Typography } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import useConfig from "../../hooks/useConfig";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";

const drawerData = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    link: "/",
  },
  {
    name: "Swipe File",
    icon: <SwipeFileIcon />,
    link: "/swipe-files",
  },
  {
    name: "Discovery",
    icon: <DiscoveryIcon />,
    link: "/discovery",
  },
  {
    name: "Team",
    icon: <TeamIcon />,
    link: "/team",
  },
  {
    name: "Help",
    icon: <HelpIcon />,
    link: "/help",
  },
];
const Sidebar = () => {
  const { drawerOpen } = useConfig();
  const location = useLocation();
  const CLICKED_URL = location.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchFolderList());
  }, []);

  return (
    <div
      id="side-panel"
      style={{
        display: "flex",
        flexDirection: "column",
        width: drawerOpen ? "15rem" : "4rem",
        color: "white",
      }}
    >
      {drawerOpen ? (
        <PanelLogo src={Logo} alt="brand-logo" />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
            width: "40%",
          }}
        >
          <LogoSmall />
        </div>
      )}
      {drawerData.map((item) => (
        <PanelItem
          to={item?.link}
          style={{
            background:
              item?.link === CLICKED_URL
                ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                : "transparent",
          }}
        >
          {item?.icon}
          {item?.name && drawerOpen && (
            <Typography marginLeft={1}>{item?.name}</Typography>
          )}
        </PanelItem>
      ))}
    </div>
  );
};

export default Sidebar;
