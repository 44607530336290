import {
  ButtonText,
  HamburgerMenu,
  HeaderButton1,
  HeaderButton2,
  HeaderLeftMenuSection,
  HeaderMenuSection,
  HeaderRightMenuSection,
  HeaderSubTitle,
  HeaderTitle,
  HeaderTitleSection,
} from "../../pageComponents/style";
import { CreateNewIcon, MenuIcon, TutorialIcon } from "../../assets/Icons";
import AvatarIcon from "../../assets/images/avatar-icon.png";
import useConfig from "../../hooks/useConfig";
import { Avatar } from "@mui/material";
import ProfilePopover from "../profilePopover";
import { useState } from "react";
import CreateNewPopover from "../createNewPopover";

const Header = ({ content }: any) => {
  const { drawerOpen, onChangeDrawer } = useConfig();
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<any>(null);
  const [createNewMenuAnchorEl, setCreateNewMenuAnchorEl] = useState<any>(null);

  const handleDrawer = () => {
    onChangeDrawer(!drawerOpen);
  };

  return (
    <>
      <HeaderMenuSection>
        <HeaderLeftMenuSection>
          <HamburgerMenu onClick={() => handleDrawer()}>
            <MenuIcon />
          </HamburgerMenu>
          <HeaderTitleSection>
            <HeaderTitle>{content?.tabTitle}</HeaderTitle>
            <HeaderSubTitle>{content?.tabSubTitle}</HeaderSubTitle>
          </HeaderTitleSection>
        </HeaderLeftMenuSection>
        <HeaderRightMenuSection>
          <HeaderButton1 variant="outlined" size="small">
            <TutorialIcon />
            <ButtonText>Tutorial</ButtonText>
          </HeaderButton1>
          <HeaderButton2
            variant="contained"
            size="small"
            onClick={(event) => setCreateNewMenuAnchorEl(event.currentTarget)}
          >
            <CreateNewIcon fill="white" />
            <ButtonText>Create New</ButtonText>
          </HeaderButton2>
          <Avatar
            src={AvatarIcon}
            onClick={(event) => setProfileMenuAnchorEl(event.currentTarget)}
          />
        </HeaderRightMenuSection>
      </HeaderMenuSection>
      <ProfilePopover
        anchorEl={profileMenuAnchorEl}
        handlePopoverClose={() => setProfileMenuAnchorEl(null)}
      />
      <CreateNewPopover
        anchorEl={createNewMenuAnchorEl}
        handlePopoverClose={() => setCreateNewMenuAnchorEl(null)}
      />
    </>
  );
};

export default Header;
