import { useContext, useEffect, useState } from "react";
import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  GoogleIconWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  CredentialSection,
  ForgotPasswordText,
  ErrorMessage,
  PasswordErrorMessage,
  ImageSection,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import {
  EyeIcon,
  EyeIconButton,
} from "../../../components/commonComponents/formFields/style";
import { GoogleIcon } from "../../../assets/Icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormValuesProp } from "../../../utils/propTypes";
import { loginValidationSchema } from "../../../utils/formValidationSchema";
import { LoginService } from "../../../services/AuthServices";
import { AuthContext } from "../../../context/AuthContext";
import Carousel from "../../../components/commonComponents/carousel";
import { CarouselContext } from "../../../context/CarouselContext";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { setAuthStatus, isAuthenticated } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormValuesProp>({
    resolver: yupResolver(loginValidationSchema),
  });
  const { carouselImages } = useContext(CarouselContext);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    const values = getValues();
    const response: any = await LoginService(values);
    reset();
    if (response.status) {
      const data = { ...response.data.data, isLoggedIn: true };
      const { token, ...user } = data;
      setAuthStatus(true);
      localStorage.setItem("User", JSON.stringify(user));
      localStorage.setItem("AccessToken", token);
      navigate(from, { replace: true });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(-1);
    }
  }, []);

  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper src={Logo} alt="app-logo" />
        <PageTitle>Log In</PageTitle>
        <AccountText>
          {"Already have an account ? "}
          <AccountLoginText to="/signup">Sign Up</AccountLoginText>
        </AccountText>
        <FormWrapper>
          <FormFieldWrapper>
            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "30.5px 14px 11.5px !important",
                  color: "white",
                  border: "3px solid white",
                  borderRadius: "2px",
                  zIndex: 2,
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-adornment-email-login"
              >
                Email
              </InputLabel>
              <OutlinedInput
                {...register("email", { required: true })}
                id="outlined-adornment-email-login"
                type="email"
              />
            </FormControl>
            {errors.email?.message && (
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            )}

            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "30.5px 14px 11.5px !important",
                  color: "white",

                  zIndex: 2,
                },
                "& > div": {
                  border: "3px solid white",
                  borderRadius: "2px",
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                htmlFor="outlined-adornment-password-login"
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                label="Password"
                endAdornment={
                  <EyeIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassword();
                    }}
                  >
                    <EyeIcon
                      src="/assets/images/eye-icon.svg"
                      alt="Show-Hide-Password-Icon"
                    />
                  </EyeIconButton>
                }
                {...register("password", { required: true })}
              />
              {errors.email?.message && (
                <PasswordErrorMessage>
                  {errors.password?.message}
                </PasswordErrorMessage>
              )}
            </FormControl>
          </FormFieldWrapper>
          <ForgotPasswordText>Forgot Password ?</ForgotPasswordText>
          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Login
            </StyledLoginButton>
            <StyledLoginButton variant="contained" withGoogle>
              <GoogleIconWrapper>
                <GoogleIcon />
              </GoogleIconWrapper>
              Login with Google
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <Carousel imageList={carouselImages.carousel_one} />
            <Carousel imageList={carouselImages.carousel_two} rtl={true} />
            <Carousel imageList={carouselImages.carousel_three} />
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default Login;
