import { useState } from "react";
import {
  BulkUploadWrapper,
  DragDropContainer,
  FontGrayNormal,
  StyledContainedButton,
} from "./style";
import { Grid, Stack, Typography } from "@mui/material";
import { CloudUploadIcon, ImageIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { useDropzone } from "react-dropzone";
import SelectBoardDropdown from "../commonComponents/selectBoardsDropdown";

const BulkUploadModal = () => {
  const theme = useTheme();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [boards, setBoards] = useState<string[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <BulkUploadWrapper>
      <Typography fontSize="1rem" fontWeight="500" color={theme.colors.gray1}>
        Use this function to upload your own content to Swipe Lounge for use in
        Boards.
      </Typography>
      <DragDropContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon />
        <Typography
          fontSize="1.125rem"
          fontWeight="600"
          color={theme.colors.black2}
          margin="1.25rem 0 0.625rem"
        >
          Upload Content
        </Typography>
        <FontGrayNormal>
          Drag and drop your .mp4, .png or .jpeg file
        </FontGrayNormal>
      </DragDropContainer>

      {uploadedFiles.length > 0 && (
        <Grid container spacing={2.5} sx={{ marginBottom: "30px" }}>
          <Grid item sm={6}>
            <FontGrayNormal>Asset</FontGrayNormal>
          </Grid>
          <Grid item sm={6}>
            <FontGrayNormal>Add to Board</FontGrayNormal>
          </Grid>
          {uploadedFiles.map((file) => (
            <>
              <Grid item sm={6}>
                <Stack direction="row" gap="10px" alignItems="center">
                  <ImageIcon />
                  <Typography
                    fontSize="1rem"
                    fontWeight="500"
                    color={theme.colors.black2}
                  >
                    {file.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <SelectBoardDropdown
                  boards={boards}
                  setBoards={setBoards}
                  showSelectedItemCount={2}
                />
              </Grid>
            </>
          ))}
        </Grid>
      )}
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          float: "right",
          padding: "0.313rem 2.688rem",
        }}
      >
        Done
      </StyledContainedButton>
    </BulkUploadWrapper>
  );
};

export default BulkUploadModal;
