import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 0.938rem;
    }
  }
`;

export const ModalIconWrapper = styled.div`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: linear-gradient(
    94.04deg,
    rgb(28, 117, 188) 2.44%,
    rgb(0, 174, 239) 112.73%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtensionBoxWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.border1}`};
  border-radius: 2px;
  margin: 1.25rem 1.875rem;
  padding: 1.875rem 3.75rem 1.875rem 1.25rem;
`;

export const ExtensionLogoWrapper = styled.div`
  width: 4.875rem;
  height: 4.875rem;
  border-radius: 9.95px;
  background: #151616;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContainedButton = styled(Button)`
  && {
    background: linear-gradient(135deg, #1c75bc, #00aeef);
    color: white;
    border-radius: 2rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
  }
  width: fit-content;
`;

export const PlatformsWrapper = styled.div`
  padding: 1.875rem 1.875rem 3rem;
`;

export const StyledOutlinedButton = styled(Button)`
  && {
    border-radius: 2rem !important;
    color: ${({ theme }) => theme.colors.black2};
    text-transform: capitalize;
    padding: 0.938rem 1.25rem;
    font-size: 0.938rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const PlatformIconWrapper = styled.img`
  margin-right: 10px;
`;

export const BulkUploadWrapper = styled.div`
  padding: 1.25rem 1.875rem;
`;

export const DragDropContainer = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.blue};
  border-radius: 2px;
  padding: 3.063rem 13.125rem;
  background: ${({ theme }) => theme.colors.background2};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.25rem 0 1.875rem;
`;

export const ModalTitle = styled(Typography)`
  && {
    font-size: 1.375rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const FontGrayNormal = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const CloseIconWrapper = styled(IconButton)`
  && {
    position: absolute;
    right: 24px;
    top: 36px;
    color: ${({ theme }) => theme.colors.black2};
  }
`;
export const CreateBoardWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 3.125rem 1.875rem 1.875rem 1.875rem;
  gap: 3rem;
`;

export const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.border1} !important;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    color: ${({ theme }) => theme.colors.black2} !important;
    font-size: 1.125rem;
    font-weight: 400;
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
  }
  & > label {
    top: -50px;
    left: -15px;
  }
`;

export const StyledSelectField = styled(Select)`
  && {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.border1} !important;
    }

    .MuiSelect-icon {
      margin: 4px 10px 0 0;
    }
  }
`;
