import React, { memo } from "react";
import { StyledGridItem } from "./style";
import { useTheme } from "styled-components";
import { Box } from "@mui/material";
import AdCard from "../adCard";

const AdCardSection = ({ item }: { item: { src: string } }) => {
  const theme = useTheme();

  return (
    <StyledGridItem
      item
      sm={7}
      sx={{
        backgroundColor: theme.colors.background4,
        paddingBlock: "6rem",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          margin: "auto",
        }}
      >
        <AdCard item={item} hideButton={true} />
      </Box>
    </StyledGridItem>
  );
};

export default memo(AdCardSection);
