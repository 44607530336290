import { InputAdornment, Select } from "@mui/material";
import { ArrowDownIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { SelectDropdownProps } from "../../utils/propTypes";

const SelectDropdown = ({
  children,
  startAdornmentIcon,
  handleChange,
  multiple = false,
  value = [],
  renderValue,
}: SelectDropdownProps) => {
  const theme = useTheme();

  return (
    <Select
      //   open={open}
      multiple={multiple}
      value={value}
      onChange={handleChange}
      renderValue={renderValue}
      sx={{
        width: "100%",
        height: "46px",
        borderRadius: "23px",
        backgroundColor: "white",
        ".MuiOutlinedInput-notchedOutline": {
          border: `0.95px solid ${theme.colors.border1} !important`,
        },
        ".MuiSelect-icon": {
          margin: "4px 10px 0 0",
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            borderRadius: 15,
          },
        },
      }}
      startAdornment={
        <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
      }
      IconComponent={(props) => <ArrowDownIcon className={props.className} />}
    >
      {children}
    </Select>
  );
};

export default SelectDropdown;
