import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import styled from "styled-components";

export const AdDrawerStyledButton = styled(Button)<{
  borderNone?: boolean;
  textColor?: string;
}>`
  && {
    border-radius: 2rem !important;
    color: ${({ theme, textColor }) =>
      textColor ? textColor : theme.colors.black2};
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
    border: ${({ borderNone, theme }) =>
      borderNone ? "none" : `1px solid ${theme.colors.border1}`};
    padding: 7px 12px;
    display: flex;
    gap: 10px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 10px !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black2};
    .MuiAccordionSummary-content {
      align-items: center;
      margin: 0 !important;
      gap: 10px;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    border-top: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const CommentsCount = styled.div`
  padding: 6px 15px;
  background-color: ${({ theme }) => theme.colors.background4};
  border-radius: 25px;
`;

export const StyledGridItem = styled(Grid)`
  && {
    overflow-y: auto;
    scrollbar-width: none;
    max-height: calc(-200px + 100vh);
    display: flex;
  }
`;

