import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { CheckedIcon, UncheckedIcon } from "../../../assets/Icons";

const CheckBoxGroup = ({
  list,
  selected,
  onChangeHandler,
  staticIcon,
}: {
  list: any;
  onChangeHandler: any;
  selected: any;
  staticIcon?: any;
}) => {
  const theme = useTheme();
  return (
    <>
      {list?.map((item: any) => {
        const isItemSelected = selected.indexOf(item._id) !== -1;
        return (
          <FormControlLabel
            key={item._id}
            control={
              <Checkbox
                disableRipple
                checked={isItemSelected}
                onChange={(event) => onChangeHandler(event, item._id)}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
              />
            }
            label={
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {typeof item.icon === "string" ? (
                  <img src={item.icon} alt={item.label} />
                ) : item.icon ? (
                  <item.icon />
                ) : (
                  staticIcon
                )}
                <Typography
                  fontSize={"1rem"}
                  fontWeight={500}
                  color={theme.colors.neutral80}
                >
                  {item.name}
                </Typography>
              </Stack>
            }
            sx={{ gap: "6px" }}
          />
        );
      })}
    </>
  );
};

export default CheckBoxGroup;
