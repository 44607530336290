import { Tab, Tabs } from "@mui/material";
import { TabContainer } from "./style";
import { useTheme } from "styled-components";
import { TabsHeaderProps } from "../../utils/propTypes";

const TabsHeader = ({ tabIndex, setTabIndex, data }: TabsHeaderProps) => {
  const theme = useTheme();
  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex!(newValue);
  };

  return (
    <TabContainer>
      <Tabs
        value={tabIndex}
        onChange={handleTabs}
        sx={{ ".MuiTabs-flexContainer": { gap: "80px" } }}
      >
        {data.map(({ id, label }) => (
          <Tab
            key={id}
            label={label}
            disableRipple
            sx={{
              "&.Mui-selected": {
                color: theme.colors.blue,
                borderBottom: "2px solid",
                fontWeight: "600",
              },
              color: theme.colors.gray1,
              textTransform: "capitalize",
              paddingInline: "0",
              paddingBottom: "16px",
              minWidth: "fit-content",
              fontSize: "1rem",
            }}
          />
        ))}
      </Tabs>
    </TabContainer>
  );
};

export default TabsHeader;
