const routeConstant = {
  login: "login",
  signup: "signup",
  dashboard: "/",
  swipeFiles: "swipe-files",
  discovery: "discovery",
  team: "team",
  help: "help",
  error: "*",
};

export default routeConstant;
