import { Outlet } from "react-router";
import Sidebar from "../sidebar";
import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const MainLayout = () => {
  const LoginCheck = () => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated;
  };
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {LoginCheck() && <Sidebar />}
      <Outlet />
    </Box>
  );
};

export default MainLayout;
