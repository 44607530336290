import * as yup from "yup";

export const signUpValidationSchema = yup.object({
  fullName: yup
    .string()
    .max(100, "Maximum 100 characters allowed!")
    .required("Full Name is Required!"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!"),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
  password: yup.string().required("Password is Required!"),
});


export const createFolderValidationSchema = yup.object().shape({
  name: yup.string().required("Folder Name is Required!"),
});

export const createBoardValidationSchema = yup.object().shape({
  name: yup.string().required("Board Name is Required!"),
  description: yup.string(),
  folderId: yup.string()
})