import axiosClient from "../apiClient";
import { createFolderParamType } from "../types/FolderTypes";

export const CreateFolderService = async (params: createFolderParamType) => {
  try {
    const response = await axiosClient.post("/folder", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const FetchFolderListService = async () => {
  try {
    const response = await axiosClient.get("/folder");
    return response;
  } catch (error) {
    return error;
  }
};
