import { MenuPopoverProps } from "../../types/Popover";
import { Button, Menu, Stack } from "@mui/material";
import { useTheme } from "styled-components";
import { useState } from "react";
import { sortingOptions } from "../../utils/constants";
import { CheckMarkIcon } from "../../assets/Icons";

const SortPopover = ({ anchorEl, handlePopoverClose }: MenuPopoverProps) => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(sortingOptions[0].value);

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "15px",
          padding: "15px",
          width: "20%",
        },
        ".MuiList-root": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        },
      }}
    >
      {sortingOptions.map((item) => (
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onClick={() => setSelectedOption(item.value)}
        >
          <Button
            disableRipple
            key={item.label}
            sx={{
              fontSize: "0.938rem",
              fontWeight: "500",
              color: theme.colors.black2,
              textTransform: "capitalize",
            }}
          >
            {item.label}
          </Button>
          {selectedOption === item.value && <CheckMarkIcon />}
        </Stack>
      ))}
    </Menu>
  );
};

export default SortPopover;
