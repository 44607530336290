export type DiscoveryStateTypes = {
  discoveryItems: DiscoveryItem[];
  viewType: ViewTypeEnum;
};

export type DiscoveryItem = {
  _id: string;
  ad_creation_time: string;
  ad_creative_bodies: string[];
  ad_creative_link_captions: string[];
  ad_creative_link_titles: string[];
  ad_delivery_start_time: string;
  age_country_gender_reach_breakdown: [
    {
      country: string;
      _id: string;
    }
  ];
  beneficiary_payers: [
    {
      beneficiary: string;
      current: boolean;
      payer: string;
      _id: string;
    }
  ];
  eu_total_reach: number;
  languages: string[];
  pageId: string;
  publisher_platforms: string[];
  target_ages: string[];
  target_gender: string;
  target_locations: [
    {
      excluded: boolean[];
      name: string;
      num_obfuscated: number;
      type: string;
      _id: string;
    }
  ];
  gallery: [];
  createdAt: string;
  updatedAt: string;
  page: {
    _id: string;
    name: string;
    page_id: string;
    createdAt: string;
    updatedAt: string;
  };
};

export enum ViewTypeEnum {
  List,
  Card,
}

