import { Box } from "@mui/material";
import {
  CommentsCount,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./style";
import { ArrowDownIcon } from "../../../assets/Icons";
import { memo } from "react";

const CommentBox = () => {
  return (
    <Box>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ArrowDownIcon />}
          sx={{ padding: "1rem" }}
        >
          Comments
          <CommentsCount>0</CommentsCount>
        </StyledAccordionSummary>
        <StyledAccordionDetails></StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default memo(CommentBox);
