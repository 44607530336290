import {
  SearchFilterSection,
  SearchInput,
  SearchIconWrapper,
  SearchWrapper,
  OptionSection,
  ViewTypeSelection,
  ViewButton1,
  ViewButton2,
  OptionButtonText,
  OptionButton,
  AppliedFilterText,
} from "../../pageComponents/style";
import SearchIcon from "@mui/icons-material/Search";
import {
  AtoZIcon,
  FilterIcon,
  GridViewIcon,
  ListViewIcon,
  VisibleIcon,
} from "../../assets/Icons";
import { useState } from "react";
import { Divider } from "@mui/material";
import MenuPopover from "../popover";
import { useTheme } from "styled-components";
import SortPopover from "../sortPopover";

const SearchAndFilter = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<any>(null);

  const handlePopover = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SearchFilterSection>
        <SearchWrapper variant="outlined">
          <SearchInput
            placeholder="AI Search..."
            endAdornment={
              <SearchIconWrapper position="end">
                <SearchIcon />
              </SearchIconWrapper>
            }
          />
        </SearchWrapper>
        <OptionSection>
          <OptionButton onClick={(event) => handlePopover(event)}>
            <FilterIcon />
            <OptionButtonText>Filter</OptionButtonText>
            <Divider orientation="vertical" variant="middle" flexItem />
            <AppliedFilterText
              color={true ? theme.colors.blue : theme.colors.gray1}
            >
              {true ? "Clear 1" : "No filters applied"}
            </AppliedFilterText>
          </OptionButton>
          <OptionButton>
            <VisibleIcon />
            <OptionButtonText>Visible</OptionButtonText>
          </OptionButton>
          <OptionButton
            onClick={(event) => setSortAnchorEl(event.currentTarget)}
          >
            <AtoZIcon />
            <OptionButtonText>Sort by</OptionButtonText>
          </OptionButton>
          <ViewTypeSelection>
            <ViewButton1>
              <ListViewIcon />
            </ViewButton1>
            <ViewButton2>
              <GridViewIcon />
            </ViewButton2>
          </ViewTypeSelection>
        </OptionSection>
      </SearchFilterSection>
      <MenuPopover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      />
      <SortPopover
        anchorEl={sortAnchorEl}
        handlePopoverClose={() => setSortAnchorEl(null)}
      />
    </>
  );
};

export default SearchAndFilter;
