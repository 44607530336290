import { Chip, Divider, MenuItem } from "@mui/material";
import styled from "styled-components";

export const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.colors.black2};
    font-weight: 500;
    font-size: 1rem;
    margin-inline: 15px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.background3};
      border-radius: 8px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    color: #e3e6ea;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    background: linear-gradient(135deg, #1c75bc, #00aeef);
  }
`;
