export const theme = {
  colors: {
    white: "#FFFFFF",
    lightBlue: "#00AEEF",
    neutral100: "#0E0E0E",
    blue: "#1C75BC",
    gray1: "#58595B",
    black2: "#414042",
    border1: "#EAECEE",
    green: "#3BB071",
    black3: "#081720",
    border2: "#F5F5F5",
    border3: "#E6E6E6",
    neutral50: "#797575",
    link: "#2563EB",
    neutral80: "#383635",
    background: "#F9F9FA",
    background2: "#EFFAFF",
    background3: "#F1F1F1",
    background4: "#FAFAFA",
    red2: "#FD3867",
  },
  fontSizes: {},
};
