import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  AdDrawerStyledButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./style";
import {
  ArrowDownIcon,
  BoardIcon,
  CreateNewIcon,
  StarIcon,
  TagsIcon,
} from "../../../assets/Icons";
import { useTheme } from "styled-components";
import SelectBoardDropdown from "../selectBoardsDropdown";
import RatingsDropdown from "../ratingsDropdown";

const OrganizationBox = ({ isSwipeFilePage }: { isSwipeFilePage: boolean }) => {
  const theme = useTheme();
  const [rating, setRating] = useState("");
  const [boards, setBoards] = useState<string[]>([]);

  return (
    <Box>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ArrowDownIcon />}
          sx={{ padding: "1.25rem" }}
        >
          Organization
        </StyledAccordionSummary>
        <StyledAccordionDetails
          sx={{
            padding: "1.25rem",
            gap: "1.25rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap="8px">
              <BoardIcon />
              <Typography
                fontSize="1rem"
                fontWeight="500"
                color={theme.colors.gray1}
              >
                Boards
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" width="60%" gap="12px">
              <SelectBoardDropdown
                boards={boards}
                setBoards={setBoards}
                showSelectedItemCount={isSwipeFilePage ? 2 : 1}
              />
              {!isSwipeFilePage && (
                <AdDrawerStyledButton sx={{ minWidth: "fit-content" }}>
                  Save Ad
                </AdDrawerStyledButton>
              )}
            </Stack>
          </Stack>

          {isSwipeFilePage && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" gap="8px">
                  <StarIcon />
                  <Typography
                    fontSize="1rem"
                    fontWeight="500"
                    color={theme.colors.gray1}
                  >
                    Rating
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  width="60%"
                  gap="12px"
                >
                  <RatingsDropdown setRating={setRating} rating={rating} />
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" gap="8px">
                  <TagsIcon />
                  <Typography
                    fontSize="1rem"
                    fontWeight="500"
                    color={theme.colors.gray1}
                  >
                    Tags
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  width="60%"
                  gap="12px"
                >
                  <AdDrawerStyledButton
                    disableTouchRipple
                    borderNone={true}
                    sx={{
                      backgroundColor: `${theme.colors.background4} !important`,
                    }}
                  >
                    #inno_glow_
                  </AdDrawerStyledButton>
                  <AdDrawerStyledButton
                    borderNone={true}
                    sx={{
                      backgroundColor: `${theme.colors.background4} !important`,
                    }}
                  >
                    <CreateNewIcon fill={theme.colors.black2} />
                    Add Tag
                  </AdDrawerStyledButton>
                </Stack>
              </Stack>
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default OrganizationBox;
