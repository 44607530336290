import styled from "styled-components";
import { FormGroup, Menu } from "@mui/material";

export const PopoverMenu = styled(Menu)`
  ul {
    padding: 0px !important;
  }
`;

export const StyledFormGroup = styled(FormGroup)<{ padding?: string }>`
  && {
    padding: ${({ padding }) => (padding ? padding : "13px 20px")};
    gap: 10px;
    height: fit-content;
    max-height: 220px;
    flex-wrap: nowrap;
    overflow-y: auto;
    margin-right: -14px;
    margin-left: -16px;
    background-color: ${({ theme }) => theme.colors.background};
  }
  &.MuiFormGroup-root::-webkit-scrollbar {
    width: 4px;
  }
  &.MuiFormGroup-root::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.border3};
  }
`;
