import { LoginParamType, SignUpParamType } from "../types/AuthTypes";
import axiosClient from "../apiClient";

export const LoginService = async (params: LoginParamType) => {
  try {
    const response = await axiosClient.post("/auth/login", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const SignUpService = async (params: SignUpParamType) => {
  try {
    const response = await axiosClient.post("/auth/signup", params);
    return response;
  } catch (error) {
    return error;
  }
};
