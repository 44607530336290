import AdsCardImage1 from "../assets/images/ads-image-1.png";
import AdsCardImage2 from "../assets/images/ads-image-2.png";
import AdsCardImage3 from "../assets/images/ads-image-3.png";
import AdsCardImage4 from "../assets/images/ads-image-4.png";
import BrandFilterImage from "../assets/images/brand-filter.png";
import FacebookIcon from "../assets/images/facebook-icon.png";
import InstagramIcon from "../assets/images/instagram-icon.png";
import TiktokIcon from "../assets/images/tiktok-icon.png";
import YoutubeIcon from "../assets/images/youtube-icon.png";
import LinkedinIcon from "../assets/images/linkedin-icon.png";
import { BoardIcon, CarouselIcon, ImageIcon, VideoIcon } from "../assets/Icons";
import AdsCardImage5 from "../assets/images/ads-image-5.png";
import AdsCardImage6 from "../assets/images/ads-image-6.png";
import AdsCardImage7 from "../assets/images/ads-image-7.png";

import AdsTableImage1 from "../assets/images/ads-table-1.png";
import AdsTableImage2 from "../assets/images/ads-table-2.png";
import AdsTableImage3 from "../assets/images/ads-table-3.png";
import AdsTableImage4 from "../assets/images/ads-table-4.png";
import AdsTableImage5 from "../assets/images/ads-table-5.png";
import AdsTableImage6 from "../assets/images/ads-table-6.png";
import AdsTableImage7 from "../assets/images/ads-table-7.png";
import AdsTableImage8 from "../assets/images/ads-table-8.png";
import AdsTableImage9 from "../assets/images/ads-table-9.png";

export const swipeFilesTabs = [
  { id: 1, label: "My Ads" },
  { id: 2, label: "Brands" },
];

export const adsStaticData = [
  { src: AdsCardImage1 },
  { src: AdsCardImage2 },
  { src: AdsCardImage3 },
  { src: AdsCardImage4 },
  { src: AdsCardImage5 },
  { src: AdsCardImage6 },
  { src: AdsCardImage7 },
  { src: AdsCardImage7 },
  { src: AdsCardImage2 },
  { src: AdsCardImage5 },
  { src: AdsCardImage4 },
  { src: AdsCardImage3 },
  { src: AdsCardImage2 },
  { src: AdsCardImage7 },
];

export const discoveryTabs = [
  { id: 1, label: "Community" },
  { id: 2, label: "Brands" },
  { id: 3, label: "Experts" },
];

export const adsTableColumns = [
  {
    id: "Ad",
    label: "Ad",
  },
  {
    id: "ativeTime",
    label: "Active Time",
  },
  {
    id: "boards",
    label: "Boards",
  },
  {
    id: "rating",
    label: "Rating",
  },
  {
    id: "tags",
    label: "Tags",
  },
];

function createAdsRow(
  id: number,
  logo: string,
  name: string,
  activeTime: string
) {
  return {
    id,
    logo,
    name,
    activeTime,
  };
}

export const adsTableRows = [
  createAdsRow(1, AdsTableImage1, "Shein", "Active Time Unavailable"),
  createAdsRow(2, AdsTableImage2, "The North Face", "Active Time Unavailable"),
  createAdsRow(3, AdsTableImage3, "Belle", "Active Time Unavailable"),
  createAdsRow(4, AdsTableImage4, "MacDonalds", "Active Time Unavailable"),
  createAdsRow(5, AdsTableImage5, "Go Pro", "Active Time Unavailable"),
  createAdsRow(6, AdsTableImage6, "Dyson", "Active Time Unavailable"),
  createAdsRow(7, AdsTableImage7, "Loveclip", "Active Time Unavailable"),
  createAdsRow(8, AdsTableImage8, "Shein", "Active Time Unavailable"),
  createAdsRow(9, AdsTableImage9, "Shein", "Active Time Unavailable"),
];

export const staticBrands = [
  { _id: 1, name: "Belle", icon: BrandFilterImage },
  { _id: 2, name: "Belle", icon: BrandFilterImage },
  { _id: 3, name: "Belle", icon: BrandFilterImage },
  { _id: 4, name: "Belle", icon: BrandFilterImage },
  { _id: 5, name: "Belle", icon: BrandFilterImage },
  { _id: 6, name: "Belle", icon: BrandFilterImage },
];

export const staticBoards = [
  { id: 1, label: "#carb_cut", icon: BoardIcon },
  { id: 2, label: "#complete_gut", icon: BoardIcon },
  { id: 3, label: "#female_shred", icon: BoardIcon },
  { id: 4, label: "#inno_cleance", icon: BoardIcon },
  { id: 5, label: "#carb_cut", icon: BoardIcon },
  { id: 6, label: "#complete_gut", icon: BoardIcon },
];

export const staticFormats = [
  { _id: 1, name: "Video", icon: VideoIcon },
  { _id: 2, name: "Image", icon: ImageIcon },
  { _id: 3, name: "Carousel", icon: CarouselIcon },
];

export const staticPlatforms = [
  { _id: 1, name: "Facebook", icon: FacebookIcon },
  { _id: 2, name: "Instagram", icon: InstagramIcon },
  { _id: 3, name: "TikTok Organic", icon: TiktokIcon },
  { _id: 4, name: "TikTok Ads", icon: TiktokIcon },
  { _id: 5, name: "YouTube", icon: YoutubeIcon },
  { _id: 6, name: "LinkedIn", icon: LinkedinIcon },
];

export const sortingOptions = [
  { value: "Newest", label: "Newest" },
  { value: "Oldest", label: "Oldest" },
  { value: "Longest Running", label: "Longest Running" },
];

export const saveAdModalTabs = [
  { id: 1, label: "Chrome Extension" },
  { id: 2, label: "Bulk Upload" },
];
