import { useEffect, useState } from "react";
import { AdsContainer, Container, HeaderSection } from "../style";
import { adsStaticData, discoveryTabs } from "../../utils/constants";
import Header from "../../components/header";
import SearchAndFilter from "../../components/searchAndFilter";
import TabsHeader from "../../components/tabsHeader";
import { Masonry } from "@mui/lab";
import AdCard from "../../components/commonComponents/adCard";
import { useDispatch } from "react-redux";
// import AdSkeleton from "../../components/commonComponents/adSkeleton";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import CreateBoardModal from "../../components/modals/createBoardModal";

const content = {
  tabTitle: "Discovery",
  tabSubTitle: "Browse 5M+ ads saved by users",
};

const Discovery = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  // const discoveryData = useSelector(discoveryItemsSelector);

  useEffect(() => {
    dispatch(DiscoverySagaActions.fetchDiscoveryList());
    dispatch(MetaDataSagaActions.fetchBoardList());
  }, [dispatch]);

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <SearchAndFilter />
        <TabsHeader
          tabIndex={tabIndex}
          data={discoveryTabs}
          setTabIndex={setTabIndex}
        />
        <AdsContainer>
          <Masonry
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
            spacing={1.5}
            sx={{ marginBottom: 0 }}
          >
            {/* {discoveryData ? (
              discoveryData?.map((item: any, index: any) => (
                <AdCard item={item} key={index} boardItems={boardData}/>
              ))
            ) : (
              <AdSkeleton />
            )} */}
            {adsStaticData?.map((item: any, index: any) => (
              <AdCard item={item} key={index} />
            ))}
          </Masonry>
        </AdsContainer>
      </HeaderSection>
      <CreateBoardModal />
    </Container>
  );
};

export default Discovery;
