import { createContext, ReactNode, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { CarouselApiResponse, CarouselContextType } from "../types/Carousel";
import { CarouselImagesService } from "../services/CarouselServices";

const initialState: CarouselContextType = {
  carouselImages: {
    carousel_one: [],
    carousel_two: [],
    carousel_three: [],
  },
};

const CarouselContext = createContext(initialState);

type CarouselProviderProps = {
  children: ReactNode;
};

function CarouselProvider({ children }: CarouselProviderProps) {
  const [carouselImages, setCarouselImages] = useState(
    initialState.carouselImages
  );

  const fetchCarouselImages = async () => {
    const response =
      (await CarouselImagesService()) as AxiosResponse<CarouselApiResponse>;
    if (response?.status === 200) {
      const carouselImagesData = response.data?.data;
      const imagesLength = Math.floor(carouselImagesData.length / 3);
      setCarouselImages({
        carousel_one: carouselImagesData.slice(0, imagesLength),
        carousel_two: carouselImagesData.slice(imagesLength, imagesLength * 2),
        carousel_three: carouselImagesData.slice(imagesLength * 2),
      });
    }
  };

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  return (
    <CarouselContext.Provider value={{ carouselImages }}>
      {children}
    </CarouselContext.Provider>
  );
}

export { CarouselProvider, CarouselContext };
