import { CircularProgress, DialogTitle, Divider, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledTextField,
} from "./style";
import { CloseIcon, CreateFolderIcon } from "../../assets/Icons";
import { CreateFolderValueProps, ModalProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createFolderValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import {
  isLoadingSelector,
  openCreateFolderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const CreateFolderModal = () => {
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CreateFolderValueProps>({
    resolver: yupResolver(createFolderValidationSchema),
  });
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector);
  const openModal = useSelector(openCreateFolderSelector);

  const onSubmit = () => {
    const values = getValues();
    dispatch(MetaDataSagaActions.createFolder(values));
    reset();
  };

  const handleCloseModal = () => {
    dispatch(MetaDataReducerAction.setOpenCreateFolder(false));
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "30px 30px 20px 30px" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <CreateFolderIcon fill="white" />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>Create Folder</ModalTitle>
            <FontGrayNormal>Folders keep your boards organized</FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="name">Folder Name</StyledInputLabel>
          <StyledTextField
            id="name"
            variant="outlined"
            {...register("name", { required: true })}
          />
          {errors.name?.message && (
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          )}
        </StyledFormControl>
      </CreateBoardWrapper>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Create Folder
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default CreateFolderModal;
