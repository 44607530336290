import axiosClient from "../apiClient";
import { createBoardParamType } from "../types/BoardTypes";

export const BoardService = async () => {
  try {
    const response = await axiosClient.get("/board");
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateBoardService = async (params: createBoardParamType) => {
  try {
    const response = await axiosClient.post("/board", params);
    return response;
  } catch (error) {
    return error;
  }
};
