import axiosClient from "../apiClient";

export const CarouselImagesService = async () => {
  try {
    const response = await axiosClient.get("auth/carousel");
    return response;
  } catch (error) {
    return error;
  }
};
