import { Box, Stack, Typography } from "@mui/material";
import { AdDrawerStyledButton } from "./style";
import {
  CodeBlockIcon,
  NavigateNextIcon,
  NavigatePrevIcon,
  UploadIcon,
} from "../../../assets/Icons";
import { StyledContainedButton } from "../../modals/style";
import { useTheme } from "styled-components";
import { memo } from "react";

const AdDrawerHeader = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="1.875rem"
    >
      <Stack direction="row" alignItems="center" gap="10px">
        <AdDrawerStyledButton>
          <NavigatePrevIcon />
          Prev
        </AdDrawerStyledButton>
        <AdDrawerStyledButton>
          Next
          <NavigateNextIcon />
        </AdDrawerStyledButton>
      </Stack>
      <Box>
        <Typography
          fontSize="1.5rem"
          fontWeight="600"
          color={theme.colors.black2}
          sx={{ display: "inline-block" }}
        >
          Ad Details :
        </Typography>
        <Typography
          fontSize="1.5rem"
          fontWeight="500"
          color={theme.colors.lightBlue}
          sx={{ display: "inline-block" }}
        >
          ID 451289754600
        </Typography>
      </Box>
      <Stack direction="row" alignItems="center" gap="10px">
        <AdDrawerStyledButton>
          <CodeBlockIcon />
          Embed
        </AdDrawerStyledButton>
        <StyledContainedButton sx={{ paddingInline: "15px", gap: "4px" }}>
          <UploadIcon color="white" />
          Share
        </StyledContainedButton>
      </Stack>
    </Stack>
  );
};

export default memo(AdDrawerHeader);
