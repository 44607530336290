import { call, put } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { DiscoveryReducerAction } from "./slice";
import { DashboardAdsService } from "../../services/DashboardServices";
import { AxiosResponse } from "axios";

const saga = createSliceSaga({
  name: "discovery-state",
  caseSagas: {
    *fetchDiscoveryList() {
      try {
        const response: AxiosResponse = yield call(DashboardAdsService);

        yield put(
          DiscoveryReducerAction.setDiscoveryItems(response?.data?.data)
        );
      } catch (error) {
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: DiscoverySagaActions,
  saga: DiscoverySaga,
  name: DiscoverySagaName,
} = saga;
