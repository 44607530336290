import { createSlice } from "@reduxjs/toolkit";
import { DiscoveryStateTypes, ViewTypeEnum } from "./types";

export const initialState: DiscoveryStateTypes = {
  discoveryItems: [],
  viewType: ViewTypeEnum.Card,
};

const discovery = createSlice({
  name: "discoveryState",
  initialState,
  reducers: {
    setDiscoveryItems(state, action) {
      state.discoveryItems = action.payload;
    },
    setViewType(state, action) {
      state.viewType = action.payload;
    },
  },
});

export const {
  actions: DiscoveryReducerAction,
  name: DiscoveryReducerName,
  reducer: DiscoveryReducer,
} = discovery;
