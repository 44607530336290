import {
  Badge,
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ExtensionBoxWrapper,
  ExtensionLogoWrapper,
  PlatformIconWrapper,
  PlatformsWrapper,
  StyledContainedButton,
  StyledOutlinedButton,
} from "./style";
import {
  ArrowOutwardIcon,
  ChromeIcon,
  ExtensionLogo,
} from "../../assets/Icons";
import { useTheme } from "styled-components";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import TiktokIcon from "../../assets/images/tiktok-icon.png";
import LinkedinIcon from "../../assets/images/linkedin-icon.png";
import { StyledDivider } from "../../globalStyle";

const PlatformButton = ({ name, icon }: { name: string; icon: string }) => {
  return (
    <StyledOutlinedButton>
      <PlatformIconWrapper src={icon} alt="" />
      {name}
      <Box style={{ marginLeft: "auto" }}>
        <ArrowOutwardIcon />
      </Box>
    </StyledOutlinedButton>
  );
};

const ChromeExtensionModal = () => {
  const theme = useTheme();

  return (
    <>
      <ExtensionBoxWrapper>
        <Stack direction="row" alignItems="start" gap="1.5rem">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<ChromeIcon />}
            sx={{ ".MuiBadge-badge": { bottom: "5%", right: "0%" } }}
          >
            <ExtensionLogoWrapper>
              <ExtensionLogo />
            </ExtensionLogoWrapper>
          </Badge>
          <Stack gap="0.938rem">
            <Typography
              fontSize="1rem"
              fontWeight="500"
              color={theme.colors.gray1}
            >
              Our Chrome Extension is the best way to save ads to your Library.
              <br />
              Simply install the extension to display a “Save Button” accross
              multiple platforms.
            </Typography>
            <StyledContainedButton
              variant="contained"
              size="small"
              sx={{ padding: "0.5rem 1.25rem" }}
            >
              Install Extension
            </StyledContainedButton>
          </Stack>
        </Stack>
      </ExtensionBoxWrapper>
      <Box>
        <Card
          sx={{
            width: "450px",
            height: "250px",
            margin: "0 1.875rem 1.875rem auto ",
            borderRadius: "10px",
          }}
        >
          <CardMedia
            component="iframe"
            title="YouTube video"
            src="https://www.youtube.com/embed/HjFH4wtcdZU"
            height="250px"
          />
        </Card>
      </Box>
      <StyledDivider />
      <PlatformsWrapper>
        <Typography
          fontSize="1.25rem"
          fontWeight="600"
          color={theme.colors.black2}
          sx={{ marginBottom: "1.875rem" }}
        >
          Platforms
        </Typography>
        <Grid container spacing={2.5}>
          <Grid item sm={6}>
            <PlatformButton name="Facebook Ad Library" icon={FacebookIcon} />
          </Grid>
          <Grid item sm={6}>
            <PlatformButton name="TikTok Creative Center" icon={TiktokIcon} />
          </Grid>
          <Grid item sm={6}>
            <PlatformButton name="TikTok Organic" icon={TiktokIcon} />
          </Grid>
          <Grid item sm={6}>
            <PlatformButton name="LinkedIn" icon={LinkedinIcon} />
          </Grid>
        </Grid>
      </PlatformsWrapper>
    </>
  );
};

export default ChromeExtensionModal;
