import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.discoveryState || initialState;

export const discoveryItemsSelector = createSelector(
  selectDomain,
  (state) => state.discoveryItems
);
export const discoveryViewTypeSelector = createSelector(
  selectDomain,
  (state) => state.viewType
);