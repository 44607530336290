import { Avatar, Badge, Box, Button, Stack, Typography } from "@mui/material";
import AvatarIcon from "../../../assets/images/avatar-icon.png";
import {
  CardContainer,
  CardDetailsWrapper,
  CardHeader,
  CardImage,
  CardImageWrapper,
  ImageOverlay,
  SaveAdButton,
} from "./style";
import { useTheme } from "styled-components";
import { useState } from "react";
import { EyeIcon } from "../../../assets/Icons";
import SaveAdPopover from "../../saveAdPopover";
import AdDrawer from "../adDrawer";
import { AdCardProps } from "../../../utils/propTypes";
// import { formatDistance } from "date-fns";

const AdCard = ({ item, hideButton }: AdCardProps) => {
  const theme = useTheme();
  const [saveAdPopupAnchorEl, setSaveAdPopupAnchorEl] = useState<any>(null);
  const [openAdDrawer, setOpenAdDrawer] = useState(false);

  const handleSaveButtonClick = (event: React.SyntheticEvent) => {
    setSaveAdPopupAnchorEl(event.currentTarget);
  };

  return (
    <>
      <CardContainer>
        <CardHeader>
          <CardDetailsWrapper>
            <Avatar src={AvatarIcon}></Avatar>
            <Stack>
              <Typography
                fontSize={16}
                color={theme.colors.black2}
                fontWeight={700}
              >
                {/* {item?.page?.name} */}
                Inno Supps
              </Typography>
              <Stack direction="row" sx={{ alignItems: "baseline" }} gap={2.5}>
                <Badge
                  variant="dot"
                  sx={{
                    ".MuiBadge-badge": {
                      top: "-6px",
                      left: "-3px",
                      backgroundColor: theme.colors.green,
                      boxShadow: `0 0 0 2px rgba(59, 176, 113, 0.2)`,
                    },
                  }}
                />
                <Typography
                  fontSize={15}
                  color={theme.colors.gray1}
                  fontWeight={400}
                >
                  {/* {formatDistance(new Date(item?.createdAt), new Date(), {
                    addSuffix: true,
                  })} */}
                  Saved just now
                </Typography>
              </Stack>
            </Stack>
          </CardDetailsWrapper>
          {!hideButton && (
            <Button
              disableRipple
              sx={{
                placeContent: "end",
                padding: 0,
                width: "fit-content",
                background: "none !important",
              }}
              onClick={() => setOpenAdDrawer(true)}
            >
              <EyeIcon />
            </Button>
          )}
        </CardHeader>
        <CardImageWrapper>
          {!hideButton && (
            <>
              <Box onClick={handleSaveButtonClick}>
                <SaveAdButton className="save-button">Save</SaveAdButton>
              </Box>
              <ImageOverlay className="overlay" />
            </>
          )}
          <CardImage src={item.src} alt={"ad"} loading="lazy" />
        </CardImageWrapper>
      </CardContainer>
      <SaveAdPopover
        anchorEl={saveAdPopupAnchorEl}
        handlePopoverClose={() => setSaveAdPopupAnchorEl(null)}
      />
      <AdDrawer
        open={openAdDrawer}
        handleClose={() => setOpenAdDrawer(false)}
        item={item}
      />
    </>
  );
};

export default AdCard;
