import { createSlice } from "@reduxjs/toolkit";
import { MetaDataState } from "./types";

export const initialState: MetaDataState = {
  isLoading: false,
  openCreateFolder: false,
  folderLists: [],
  boardItems: [],
  boardIds: [],
  openBoardModal: false,
};

const metaData = createSlice({
  name: "metaDataState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOpenCreateFolder(state, action) {
      state.openCreateFolder = action.payload;
    },

    setFolderItems(state, action) {
      state.folderLists = action.payload;
    },
    setBoardItems(state, action) {
      state.boardItems = action.payload;
    },
    setBoardId(state, action) {
      const boardId = action.payload;
      const index = state.boardIds.indexOf(boardId);
      if (index === -1) {
        state.boardIds.push(boardId);
      } else {
        state.boardIds.splice(index, 1);
      }
    },
    setBoardItem(state, action) {
      state.boardItems.push(action.payload);
    },
    setOpenBoardModal(state, action) {
      state.openBoardModal = action.payload;
    },
    setBoardIds(state, action) {
      const { selectAll, id, checked } = action.payload;
      if (selectAll && checked) {
        state.boardIds = state.boardItems.map((item) => item._id);
      } else if (selectAll && !checked) {
        state.boardIds = [];
      } else {
        const index = state.boardIds.indexOf(id);
        if (index === -1) {
          state.boardIds.push(id);
        } else {
          state.boardIds.splice(index, 1);
        }
      }
    },
  },
});

export const {
  actions: MetaDataReducerAction,
  name: MetaDataReducerName,
  reducer: MetaDataReducer,
} = metaData;
