import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import styled from "styled-components";

export const SearchWrapper = styled(FormControl)`
  background-color: white;
  width: 100%;
`;

export const SearchIconWrapper = styled(InputAdornment)`
  margin-right: -4px;
`;

export const SearchInput = styled(OutlinedInput)`
  && {
    border-radius: 8px;
    border: ${({ theme }) => `1px solid ${theme.colors.border3}`};
    height: 46px;
    color: ${({ theme }) => theme.colors.gray1};
    font-weight: 500;
    font-size: 1rem;
  }
`;
