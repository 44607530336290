import { Route, Routes } from "react-router-dom";
import "./App.css";
// Components
import Login from "./pageComponents/auth/login";
import Discovery from "./pageComponents/discovery";
import SwipeFiles from "./pageComponents/swipeFiles";
import SignupForm from "./pageComponents/auth/signup";
import MainLayout from "./components/mainLayout";
import GuestGuard from "./routes/GuestGuard";
import Dashboard from "./pageComponents/dashboard";
import ErrorPage from "./pageComponents/errorPage";
import routeConstant from "./utils/routeConstant";
import { CarouselProvider } from "./context/CarouselContext";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DiscoveryReducer,
  DiscoveryReducerName,
} from "./store/discovery/slice";
import { DiscoverySaga, DiscoverySagaName } from "./store/discovery/sagas";
import { MetaDataReducer, MetaDataReducerName } from "./store/metadata/slice";
import { MetaDataSaga, MetaDataSagaName } from "./store/metadata/sagas";

const App = () => {
  useInjectReducer({
    reducer: DiscoveryReducer,
    key: DiscoveryReducerName,
  });

  useInjectReducer({
    reducer: MetaDataReducer,
    key: MetaDataReducerName,
  });

  useInjectSaga({
    saga: DiscoverySaga,
    key: DiscoverySagaName,
  });

  useInjectSaga({
    saga: MetaDataSaga,
    key: MetaDataSagaName,
  });

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {/* Public Routes */}
        <Route
          path={routeConstant?.login}
          element={
            <CarouselProvider>
              <Login />
            </CarouselProvider>
          }
        />
        <Route
          path={routeConstant?.signup}
          element={
            <CarouselProvider>
              <SignupForm />
            </CarouselProvider>
          }
        />

        {/* Protected Routes */}
        <Route element={<GuestGuard />}>
          <Route path={routeConstant?.dashboard} element={<Dashboard />} />
          <Route path={routeConstant?.discovery} element={<Discovery />} />
          <Route path={routeConstant?.swipeFiles} element={<SwipeFiles />} />
          <Route path={routeConstant?.team} />
          <Route path={routeConstant?.help} />
        </Route>

        {/* Other Routes */}
      </Route>
      <Route path={routeConstant?.error} element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
