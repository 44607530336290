import SelectDropdown from "../../selectDropdown";
import { StarIcon, StarRatingIcon } from "../../../assets/Icons";
import { Chip, SelectChangeEvent } from "@mui/material";
import { StyledMenuItem } from "../../../globalStyle";
import { RatingsDropdownProps } from "../../../utils/propTypes";

const RatingsDropdown = ({ setRating, rating }: RatingsDropdownProps) => {
  return (
    <SelectDropdown
      startAdornmentIcon={<StarIcon />}
      renderValue={(selected: any) => (
        <Chip
          label={Array.from({ length: selected }, () => (
            <StarRatingIcon />
          ))}
        />
      )}
      handleChange={(event: SelectChangeEvent) => setRating(event.target.value)}
      value={rating}
    >
      {[5, 4, 3, 2, 1].map((rating) => (
        <StyledMenuItem value={rating}>
          {Array.from({ length: rating }, () => (
            <StarRatingIcon />
          ))}
        </StyledMenuItem>
      ))}
    </SelectDropdown>
  );
};

export default RatingsDropdown;
