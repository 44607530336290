import React from "react";
import Header from "../../components/header";
import { Container, HeaderSection } from "../style";
import { useSelector } from "react-redux";
import { discoveryItemsSelector } from "../../store/discovery/selector";
import CreateBoardModal from "../../components/modals/createBoardModal";

const content = {
  tabTitle: "Dashboard",
  tabSubTitle: "Your Creative Strategy Hub",
};
const Dashboard = () => {
  const data = useSelector(discoveryItemsSelector);
  console.log("DataFromSelector>>>>", data);

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
      </HeaderSection>
      <CreateBoardModal />
    </Container>
  );
};

export default Dashboard;
