import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.metaDataState || initialState;
export const isLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const openCreateFolderSelector = createSelector(
  selectDomain,
  (state) => state.openCreateFolder
);

export const folderListsSelector = createSelector(
  selectDomain,
  (state) => state.folderLists
);

export const boardItemsSelector = createSelector(
  selectDomain,
  (state) => state.boardItems
);

export const boardIdsSelector = createSelector(
  selectDomain,
  (state) => state.boardIds
);


export const openBoardModalSelector = createSelector(
  selectDomain,
  (state) => state.openBoardModal
)
