import axiosClient from "../apiClient";

export const DashboardAdsService = async () => {
  try {
    const response = await axiosClient.post("/ad/list");
    return response;
  } catch (error) {
    return error;
  }
};
