import styled from "styled-components";
import { Button, Grid } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

export const LoginLayoutContainer = styled(Grid)`
  padding: 4% 0 0 6%;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  overflow: hidden;
  height: 100vh;
  @media (max-width: 899px) {
    overflow: auto;
    height: 100%;
    padding: 4% 6% 0 6%;
  }
`;

export const LogoWrapper = styled.img`
  width: 21rem;
  margin-bottom: 2%;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  margin: 3% 0 2% 0;
  @media (max-width: 1440px) {
    font-size: 1.875rem;
  }
`;

export const AccountText = styled.span`
  font-weight: 400;
  font-size: 1.275rem;
  @media (max-width: 1440px) {
    font-size: 1.125rem;
  }
`;

export const AccountLoginText = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const FormWrapper = styled.form`
  margin-top: 7%;
  @media (max-width: 1440px) {
    margin-top: 25px;
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  @media (max-width: 1440px) {
    gap: 15px;
  }
`;

export const TermsAndPolicyText = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 20.94px;
  display: flex;
  align-items: center;
  @media (max-width: 1440px) {
    font-size: 0.875rem;
  }
`;

export const BottomButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.7rem;
  @media (max-width: 1440px) {
    margin-top: 25px;
  }
`;

export const StyledLoginButton = styled(Button)<{ withGoogle?: boolean }>`
  height: 3rem;
  width: 100% !important;
  && {
    border-radius: 36px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 20.94px;
    text-transform: capitalize;
    background: ${({ withGoogle, theme }) =>
      withGoogle
        ? theme.colors.white
        : "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"};
    color: ${({ withGoogle, theme }) =>
      withGoogle ? theme.colors.neutral100 : theme.colors.white};
  }

  @media (max-width: 1440px) {
    width: 422px;
    && {
      font-size: 1rem;
    }
  }

  &:hover,
  &:focus {
    && {
      background: ${({ withGoogle, theme }) =>
        withGoogle
          ? theme.colors.white
          : "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"};
      color: ${({ withGoogle, theme }) =>
        withGoogle ? theme.colors.neutral100 : theme.colors.white};
    }
  }
`;

export const GoogleIconWrapper = styled.span`
  margin-right: 5px;
`;

export const ForgotPasswordText = styled.div`
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: end;
  @media (max-width: 1440px) {
    font-size: 0.875rem;
  }
`;

export const CredentialSection = styled(Grid)`
  padding-right: 10%;
  @media (max-width: 899px) {
    padding-right: 0;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const PasswordErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

export const ImageSection = styled(Grid)`
  position: absolute;
  left: 72%;
  top: -43%;
  transform: rotate(341deg);
  display: flex;
  .slick-list {
    height: 100% !important;
  }
  .slick-vertical .slick-slide {
    margin-bottom: 15px;
  }
  @media (max-width: 1700px) {
    left: 78%;
  }
  @media (max-width: 1450px) {
    left: 84%;
  }
  @media (max-width: 1230px) {
    left: 89%;
  }
  @media (max-width: 1119px) {
    left: 92%;
  }
  @media (max-width: 1024px) {
    left: 95%;
  }
  @media (max-width: 899px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const CarouselContainer = styled.div`
  z-index: -1;
`;

export const PanelItem = styled(NavLink)`
  color: white !important;
  display: flex !important;
  text-decoration: none !important;
  justify-content: start !important;
  margin: 1rem !important;
  padding: 0.5rem !important;
  border-radius: 5px !important;
  text-transform: capitalize !important;
`;

export const PanelLogo = styled.img`
  display: flex !important;
  justify-content: start !important;
  margin: 1rem !important;
`;
