import { useState } from "react";
import { AdsContainer, Container, HeaderSection } from "../style";
import { adsStaticData, swipeFilesTabs } from "../../utils/constants";
import AdCard from "../../components/commonComponents/adCard";
import { Masonry } from "@mui/lab";
import Header from "../../components/header";
import SearchAndFilter from "../../components/searchAndFilter";
import TabsHeader from "../../components/tabsHeader";
import CreateBoardModal from "../../components/modals/createBoardModal";
// import AdTable from "../../components/commonComponents/adTable";
// import { useSelector } from "react-redux";
// import { discoveryItemsSelector } from "../../store/discovery/selector";

const content = {
  tabTitle: "Swipe Files",
  tabSubTitle: "Your saved FB, TikTok & LinkedIn Ads",
};

const SwipeFiles = () => {
  // const data = useSelector(discoveryItemsSelector);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <SearchAndFilter />
        <TabsHeader
          tabIndex={tabIndex}
          data={swipeFilesTabs}
          setTabIndex={setTabIndex}
        />
        <AdsContainer>
          <Masonry
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
            spacing={1.5}
            sx={{ marginBottom: 0 }}
          >
            {/* {data ? (
              data?.map((item: any, index: any) => (
                <AdCard item={item} key={index} />
              ))
            ) : (
              <AdSkeleton />
            )} */}
            {adsStaticData?.map((item: any, index: any) => (
              <AdCard item={item} key={index} />
            ))}
          </Masonry>
          {/* <div>
            <AdTable />
          </div> */}
        </AdsContainer>
      </HeaderSection>
      <CreateBoardModal />
    </Container>
  );
};

export default SwipeFiles;
