import * as React from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { MenuPopoverProps } from "../../types/Popover";
import { useState } from "react";
import { PopoverMenu, StyledFormGroup } from "./style";
import { useTheme } from "styled-components";
import {
  staticBrands,
  staticFormats,
  staticPlatforms,
} from "../../utils/constants";
import CheckBoxGroup from "../commonComponents/checkboxGroup";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import { useDispatch, useSelector } from "react-redux";
import {
  boardIdsSelector,
  boardItemsSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { BoardIcon } from "../../assets/Icons";

const AccordionWrapper = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ background: "white !important", padding: "1rem" }}
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.8rem", color: "#414042" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: `0 0 0 ${theme.spacing(1)}`,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#F9F9FA",
}));

const SelectAllHeaderWrapper = ({
  selectAllHandler,
  selectedAll,
}: {
  selectAllHandler: () => void;
  selectedAll: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "13px",
      }}
    >
      <Typography
        fontSize={"0.938rem"}
        fontWeight={500}
        color={theme.colors.neutral50}
      >
        Show from
      </Typography>
      <Button
        sx={{
          color: theme.colors.link,
          fontWeight: 600,
          fontSize: "0.938rem",
          textTransform: "capitalize",
          padding: 0,
        }}
        onClick={selectAllHandler}
      >
        {selectedAll ? "Deselect All" : "Select All"}
      </Button>
    </Stack>
  );
};

const MenuPopover = ({ anchorEl, handlePopoverClose }: MenuPopoverProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>("");
  const [selectedBrands, setSelectedBrands] = useState<number[]>([]);
  // const [selectedBoards, setSelectedBoards] = useState<number[]>([]);
  const [selectedFormats, setSelectedFormats] = useState<number[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<number[]>([]);

  const boardLists = useSelector(boardItemsSelector);

  const selectedBoard = useSelector(boardIdsSelector);

  const dispatch = useDispatch();

  const handleSelectCheckbox = ({
    checked,
    list,
    setSelected,
    selected,
    selectAll,
    id,
  }: {
    checked: boolean;
    list: any;
    setSelected?: any;
    selected: number[];
    selectAll?: boolean;
    id?: string;
  }) => {
    if (checked) {
      selectAll
        ? setSelected(list.map((n: any) => n._id))
        : setSelected([...selected, id!]);
    } else {
      selectAll
        ? setSelected([])
        : setSelected(selected.filter((item) => item.toString() !== id));
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <PopoverMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "15px",
          overflow: "hidden",
          width: "20%",
        },
      }}
    >
      <AccordionWrapper
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel1" ? 700 : 500}
          >
            Brand
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterSearchbox placeholder="Search for brands..." />
          <SelectAllHeaderWrapper
            selectedAll={staticBrands.length === selectedBrands.length}
            selectAllHandler={() =>
              handleSelectCheckbox({
                checked: !(staticBrands.length === selectedBrands.length),
                list: staticBrands,
                setSelected: setSelectedBrands,
                selected: selectedBrands,
                selectAll: true,
              })
            }
          />
          <Divider
            sx={{ color: theme.colors.border3, marginInline: "-16px" }}
          />
          <StyledFormGroup>
            <CheckBoxGroup
              list={staticBrands}
              selected={selectedBrands}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                handleSelectCheckbox({
                  checked: event.target.checked,
                  list: staticBrands,
                  setSelected: setSelectedBrands,
                  selected: selectedBrands,
                  selectAll: false,
                  id,
                })
              }
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper>

      <AccordionWrapper
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel2" ? 700 : 500}
          >
            Board
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterSearchbox placeholder="Search for boards..." />
          <SelectAllHeaderWrapper
            selectedAll={boardLists.length === selectedBoard.length}
            selectAllHandler={() =>
              dispatch(
                MetaDataReducerAction.setBoardIds({
                  selectAll: true,
                  checked: !(boardLists.length === selectedBoard.length),
                })
              )
            }
          />
          <Divider
            sx={{ color: theme.colors.border3, marginInline: "-16px" }}
          />
          <StyledFormGroup>
            <CheckBoxGroup
              list={boardLists}
              selected={selectedBoard}
              staticIcon={<BoardIcon />}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                dispatch(
                  MetaDataReducerAction.setBoardIds({
                    selectAll: false,
                    id: id,
                  })
                )
              }
              
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper>

      <AccordionWrapper
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel3" ? 700 : 500}
          >
            Format
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledFormGroup padding="0 20px">
            <CheckBoxGroup
              list={staticFormats}
              selected={selectedFormats}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                handleSelectCheckbox({
                  checked: event.target.checked,
                  list: staticFormats,
                  setSelected: setSelectedFormats,
                  selected: selectedFormats,
                  selectAll: false,
                  id,
                })
              }
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper>

      <AccordionWrapper
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel4" ? 700 : 500}
          >
            Platform
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledFormGroup padding="0 20px">
            <CheckBoxGroup
              list={staticPlatforms}
              selected={selectedPlatforms}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                handleSelectCheckbox({
                  checked: event.target.checked,
                  list: staticPlatforms,
                  setSelected: setSelectedPlatforms,
                  selected: selectedPlatforms,
                  selectAll: false,
                  id,
                })
              }
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper>
    </PopoverMenu>
  );
};

export default MenuPopover;
