import { MenuPopoverProps } from "../../types/Popover";
import { Menu, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import {
  CreateBoardIcon,
  CreateFolderIcon,
  CreateNewIcon,
  UploadIcon,
} from "../../assets/Icons";
import SaveNewAdModal from "../modals/saveNewAdModal";
import { useState } from "react";
import CreateFolderModal from "../modals/createFolderModal";
import { useDispatch, useSelector } from "react-redux";
import {
  openCreateFolderSelector,
} from "../../store/metadata/selector";
import { StyledDivider } from "../../globalStyle";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const ItemRow = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => (
  <Stack
    direction="row"
    sx={{ alignItems: "center", gap: "0.625rem", cursor: "pointer" }}
    onClick={onClick}
  >
    {children}
  </Stack>
);

const StyledText = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="0.938rem"
      fontWeight="500"
      color={theme.colors.black2}
    >
      {children}
    </Typography>
  );
};

export const CustomDivider = () => (
  <StyledDivider sx={{ marginInline: "-0.938rem" }} />
);

const CreateNewPopover = ({
  anchorEl,
  handlePopoverClose,
}: MenuPopoverProps) => {
  const theme = useTheme();
  const [openSaveNewAd, setOpenSaveNewAd] = useState(false);
  const [openManualUpload, setOpenManualUpload] = useState(false);

  const dispatch = useDispatch();
  const openCreateFolder = useSelector(openCreateFolderSelector);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiMenu-paper": {
            borderRadius: "0.938rem",
            padding: "0.938rem",
            width: "13%",
          },
          ".MuiList-root": {
            display: "flex",
            flexDirection: "column",
            gap: "0.938rem",
          },
        }}
      >
        <ItemRow onClick={() => setOpenSaveNewAd(true)}>
          <CreateNewIcon fill={theme.colors.black2} />
          <StyledText>Save New Ad</StyledText>
        </ItemRow>
        <CustomDivider />
        <ItemRow onClick={() => setOpenManualUpload(true)}>
          <UploadIcon color={theme.colors.black2} />
          <StyledText>Manual Upload</StyledText>
        </ItemRow>
        <CustomDivider />
        <ItemRow
          onClick={() =>
            dispatch(MetaDataReducerAction.setOpenBoardModal(true))
          }
        >
          <CreateBoardIcon />
          <StyledText>Create Board</StyledText>
        </ItemRow>
        <CustomDivider />
        <ItemRow
          onClick={() =>
            dispatch(MetaDataReducerAction.setOpenCreateFolder(true))
          }
        >
          <CreateFolderIcon fill="#414042" />
          <StyledText>Create Folder</StyledText>
        </ItemRow>
      </Menu>
      {openSaveNewAd && (
        <SaveNewAdModal
          modalTabIndex={0}
          open={openSaveNewAd}
          handleClose={() => setOpenSaveNewAd(false)}
        />
      )}
      {openManualUpload && (
        <SaveNewAdModal
          modalTabIndex={1}
          open={openManualUpload}
          handleClose={() => setOpenManualUpload(false)}
        />
      )}
      {openCreateFolder && <CreateFolderModal />}
    </>
  );
};

export default CreateNewPopover;
