import { createContext, ReactNode } from "react";
import { ContextProps } from "../types/Config";
import useLocalStorage from "../hooks/useLocalStorage";
const initialState: ContextProps = {
  drawerOpen: true,
  onChangeDrawer: () => {},
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage("config-ts", {
    drawerOpen: initialState.drawerOpen,
  });

  const onChangeDrawer = (drawerOpen: boolean) => {
    setConfig({
      ...config,
      drawerOpen,
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeDrawer,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
