import { Card, CardContent, Grid, Typography } from "@mui/material";
import styled from "styled-components";

const ErrorWrapper = styled("div")({
  maxWidth: "100%",
  margin: "0 auto",
  textAlign: "center",
});

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ErrorPage = () => {
  return (
    <ErrorCard>
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ErrorWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1" component="div">
                    404 Not Found
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    The page you are looking was not found!
                  </Typography>
                </Grid>
              </Grid>
            </ErrorWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  );
};

export default ErrorPage;
